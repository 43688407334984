/** ref
 * https://github.com/module-federation/core/issues/2672#issuecomment-2235190899
 */
const sharedStrategy = () => ({
  name: 'shared-strategy-plugin',
  beforeInit(args) {
    args.userOptions.shareStrategy = 'loaded-first';
    return args;
  },
});

export default sharedStrategy;
